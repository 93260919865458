import React from 'react';
import { Translate } from 'react-localize-redux';
import {
  change,
  touch,
  Field,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import Percentage from '../../../components/Fields/Percentage';
import Price from '../../../components/Fields/Price';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

import {
  capitalSelector,
  getSecFromPair,
  qtyPrimSelector,
  qtySecSelector,
  getPrimFromPair,
  getPartFromPourcentage,
  currentPriceSelector,
  parseToFloat,
  stepPriceSelector,
  stepQtySelector,
  getPourcentageFromPart,
  getPartFromSecQty,
  getSecFromFirst,
  getFirstFromSec,
  getSecQtyFromPart,
  leverageBuySelector,
  leverageSellSelector,
  tokenPrimSelector,
  tokenSecSelector,
  isNull,
  isEmpty,
  getPriceFromProfit,
  secPriceSelector,
  getProfitFromPrice,
} from '../../../helpers/Common';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Helper from '../../../components/Hover/Helper';
import { SmallCheckBox } from '../../../components/Fields/SmallCheckBox';
import LabelCheckbox from '../../../components/Form/LabelCheckbox';
import {
  getAveragePrice,
  getLeverageLimit,
  haveLeverage,
} from '../../../helpers/order';
import { KeyExchangeTable } from '../../../helpers/exchange';
import MyTooltip from '../../../components/MyTooltip';

import { v4 as uuidv4 } from 'uuid';
import { createTradingViewString } from '../../../redux/order/actions.js';
import CopyField from '../../../components/Fields/CopyField.jsx';
import { getUrl } from '../../../helpers/fetcher.js';
import RadioTVTypeContainer from './RadioTVTypeContainer';
import { dangerColor } from '../../../assets/jss/material-dashboard-react.jsx';
import TriggerLinesContainer from './TriggerLinesContainer.jsx';

class StartContainer extends React.PureComponent {
  changeAndTouch = (formName, name, value) => {
    const { change, touch } = this.props;
    change(formName, name, value);
    touch(formName, name);
  };

  componentDidUpdate = (prevProps) => {
    const { formName, currentPrice, starts, isBuy, stepPrice } = this.props;
    const { starts: prevStarts } = prevProps;

    if (isNull(starts) || isNull(prevStarts)) return;

    let isAvgPriceNew = false;

    for (let i = 0; i < starts.length; i++) {
      const actStart = starts[i];
      const prevStart = prevStarts[i];

      if (
        isEmpty(actStart) ||
        actStart.isDone ||
        isEmpty(prevStart) ||
        (isEmpty(actStart.price) &&
          isEmpty(actStart.pourcentage) &&
          actStart.type !== 'Market')
      )
        continue;

      if (
        !isEmpty(currentPrice) &&
        ((currentPrice !== prevProps.currentPrice &&
          (actStart.price === prevProps.currentPrice ||
            isEmpty(actStart.price))) ||
          (actStart.type === 'Market' &&
            !actStart.triggerCB &&
            actStart.price !== currentPrice))
      )
        this.changeAndTouch(formName, `starts.${i}.price`, currentPrice);

      if (
        actStart.price !== prevStart.price ||
        actStart.pourcentage !== prevStart.pourcentage
      )
        isAvgPriceNew = true;

      const nPriceTrailing = parseFloat(
        getPriceFromProfit(-1 * actStart.trailing, isBuy, actStart.price),
      );
      const newPriceTrailing = !isEmpty(nPriceTrailing)
        ? parseToFloat(nPriceTrailing, stepPrice)
        : null;
      const nProfitTrailing =
        -1 *
        parseFloat(
          getProfitFromPrice(actStart.trailingPrice, isBuy, actStart.price),
        );
      const newProfitTrailing = !isEmpty(nProfitTrailing)
        ? parseToFloat(nProfitTrailing, 0.01)
        : null;
      if (
        actStart.price !== prevStart.price ||
        (actStart.trailing !== prevStart.trailing &&
          newProfitTrailing !== actStart.trailing)
      ) {
        this.changeAndTouch(
          formName,
          `starts.${i}.trailingPrice`,
          newPriceTrailing === 0 ? undefined : newPriceTrailing,
        );
      } else if (
        actStart.trailingPrice !== prevStart.trailingPrice &&
        newPriceTrailing !== actStart.trailingPrice
      ) {
        this.changeAndTouch(
          formName,
          `starts.${i}.trailing`,
          newProfitTrailing === 0 ? undefined : newProfitTrailing,
        );
      }

      // if (
      //   actStart.price !== prevStart.price &&
      //   actStart.triggerPrice === prevStart.price
      // ) {
      //   console.log('change trigger price');
      //   this.changeAndTouch(
      //     formName,
      //     `starts.${i}.triggerPrice`,
      //     actStart.price,
      //   );
      // }

    }
    if (isAvgPriceNew)
      this.changeAndTouch(
        formName,
        'startAveragePrice',
        getAveragePrice(starts, stepPrice),
      );
  };

  changeValue = (elemId, newValue) => {
    const {
      change,
      formName,
      lotStep,
      capital,
      stepPrice,
      pourcentage,
      secPrice,
      leverage,
      leverageCB,
      isBuyDone,
      exchange,
      isBuy,
      typeStart,
      leverageBuy,
      leverageSell,
      startAveragePrice,
    } = this.props;

    if (isBuyDone) return;
    change(formName, 'lastUpdate', elemId);
    const isLeverage =
      exchange &&
      haveLeverage(exchange.exchange, isBuy, leverageBuy, leverageSell);
    const leverageUse = leverageCB && isLeverage ? leverage : 1;
    const exchangeName = exchange?.exchange ?? '';
    const isTaker = typeStart === 'Market';
    // console.log(elemId, startPrice);
    if (elemId === 'firstQty') {
      const newSecQty = getSecFromFirst(newValue, startAveragePrice, stepPrice);
      const partUse = getPartFromSecQty(newSecQty, secPrice);

      this.changeAndTouch(formName, 'secQty', newSecQty);
      this.changeAndTouch(formName, 'partUse', partUse);
      const percFromPart = getPourcentageFromPart(
        partUse,
        capital,
        leverageUse,
      );
      this.changeAndTouch(formName, 'pourcentage', percFromPart);
    } else if (elemId === 'secQty') {
      const newFirstQty = getFirstFromSec(
        newValue,
        startAveragePrice,
        lotStep,
        exchangeName,
        leverageUse,
        isBuy,
        isTaker,
      );
      this.changeAndTouch(formName, 'firstQty', newFirstQty);

      const partUse = getPartFromSecQty(newValue, secPrice);
      this.changeAndTouch(formName, 'partUse', partUse);

      const percFromPart = getPourcentageFromPart(
        partUse,
        capital,
        leverageUse,
      );
      this.changeAndTouch(formName, 'pourcentage', percFromPart);
    } else if (elemId === 'pourcentage') {
      let newPartUse = getPartFromPourcentage(capital, newValue, leverageUse);
      let qtySec = getSecQtyFromPart(newPartUse, secPrice, stepPrice);
      const firstQty = getFirstFromSec(
        qtySec,
        startAveragePrice,
        lotStep,
        exchangeName,
        leverageUse,
        isBuy,
        isTaker,
      );
      // qtySec = getSecFromFirst(firstQty, startPrice, stepPrice);
      // newPartUse = getPartFromSecQty(qtySec, secPrice);

      this.changeAndTouch(formName, 'partUse', newPartUse);
      this.changeAndTouch(formName, 'secQty', qtySec);
      this.changeAndTouch(formName, 'firstQty', firstQty);
    } else if (elemId === 'partUse') {
      const pourcentage = getPourcentageFromPart(
        newValue,
        capital,
        leverageUse,
      );
      this.changeAndTouch(formName, 'pourcentage', pourcentage);

      const qtySec = getSecQtyFromPart(newValue, secPrice, stepPrice);
      this.changeAndTouch(formName, 'secQty', qtySec);

      const qtyFirst = getFirstFromSec(
        qtySec,
        startAveragePrice,
        lotStep,
        exchangeName,
        leverageUse,
        isBuy,
        isTaker,
      );
      this.changeAndTouch(formName, 'firstQty', qtyFirst);
    } else if (elemId === 'leverage') {
      this.changeAndTouch(formName, 'leverage', newValue);

      const newPartUse = getPartFromPourcentage(capital, pourcentage, newValue);
      this.changeAndTouch(formName, 'partUse', newPartUse);

      const qtySec = getSecQtyFromPart(newPartUse, secPrice, stepPrice);
      this.changeAndTouch(formName, 'secQty', qtySec);

      const fstFromSec = getFirstFromSec(
        qtySec,
        startAveragePrice,
        lotStep,
        exchangeName,
        newValue,
        isBuy,
        isTaker,
      );
      this.changeAndTouch(formName, 'firstQty', fstFromSec);
    }
  };

  getToolTipQty = (token) => {
    return isNull(token) ? (
      ' '
    ) : (
      <div>
        <Translate id={'exchanges.' + KeyExchangeTable.AMOUNT.T} />:{' '}
        {token.Amount}
        <br />
        <Translate id={'exchanges.' + KeyExchangeTable.LOCKED.T} />:{' '}
        {token.Locked}
        <br />
        <Translate id={'exchanges.' + KeyExchangeTable.LOCKEDONWOT.T} />:{' '}
        {token.LockedOnWot}
      </div>
    );
  };

  render() {
    const {
      formName,
      change,
      currentPrice,
      capital,
      pair,
      qtyPrim,
      qtySec,
      tokenPrim,
      tokenSec,
      isBuy,
      TVType,
      leverageCB,
      leverageBuy,
      leverageSell,
      isBuyDone,
      isUpdate,
      stepPrice,
      lotStep,
      exchange,
      leverage,
      dontDoStart,
      isPaperTrading,
      isTemplate,
      serverUrl,
      templateGuid,
      syncErrors,
      order,
    } = this.props;
    const TVJson = createTradingViewString(templateGuid, TVType);
    const primStr = getPrimFromPair(pair);
    const secStr = getSecFromPair(pair);
    const isBitmex = exchange && exchange.exchange === 'Bitmex';
    const isKraken = exchange && exchange.exchange === 'Kraken';
    let isLeverage =
      exchange &&
      haveLeverage(exchange.exchange, isBuy, leverageBuy, leverageSell);
    const minLeverage = exchange
      ? getLeverageLimit(
        'min',
        exchange.exchange,
        isBuy,
        leverageBuy,
        leverageSell,
      )
      : 0;
    const maxLeverage = exchange
      ? getLeverageLimit(
        'max',
        exchange.exchange,
        isBuy,
        leverageBuy,
        leverageSell,
      )
      : 0;
    const tooltipPrimQty = this.getToolTipQty(tokenPrim);
    const tooltipSecQty = this.getToolTipQty(tokenSec);
    const isUpdateTemplate = isUpdate && isTemplate;

    const errorsList =
      syncErrors && syncErrors.starts ? syncErrors.starts.split('\n') : [];

    if (isTemplate) {
      if (isNull(serverUrl)) {
        change(
          formName,
          'serverUrl',
          `${getUrl()}api/v2/Order/TradingView`,
        );
      }
      if (isNull(templateGuid)) {
        change(formName, 'templateGuid', isUpdate ? order.Triggers[0].TradingViewGuid : uuidv4());
      }
    }

    return (
      <GridItem xs={12} sm={12} md={4}>
        <Card className="smart-trade-bloc">
          <CardBody>
            <GridContainer>
              {!isUpdate && (
                <GridItem xs={12} sm={12} md={12}>
                  <Field
                    name="isTemplate"
                    id="isTemplate"
                    component={SmallCheckBox}
                    onChange={() => {
                      if (isNull(serverUrl)) {
                        change(
                          formName,
                          'serverUrl',
                          `${getUrl()}api/v2/Order/TradingView`,
                        );
                      }
                      if (isNull(templateGuid)) {
                        change(formName, 'templateGuid', uuidv4());
                      }
                    }}
                  />
                  <LabelCheckbox htmlFor="isTemplate">
                    <Translate id="orders.botTradingView" />
                  </LabelCheckbox>
                </GridItem>
              )}
              {isTemplate && (
                <>
                  <GridItem xs={12} sm={12} md={12}>
                    <br />
                    <RadioTVTypeContainer
                      formName={formName}
                      name="TVType"
                      disabled={isUpdateTemplate}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      <br />
                      <CopyField
                        name="serverUrl"
                        copycontent={serverUrl}
                        value={serverUrl}
                        required={false}
                        label={<Translate id="smartTrade.url" />}
                      />
                      <CopyField
                        style={{ margin: '-10px -0px 0px 0px' }}
                        name={'templateGuid'}
                        value={TVJson}
                        copycontent={TVJson}
                        required={false}
                        label={<Translate id="smartTrade.tradingview.message" />}
                      />
                    </div>
                  </GridItem>
                </>
              )}
              {!isTemplate && (
                <>
                  <GridItem xs={12} sm={12} md={12}>
                    {/*{!dontDoStart && (*/}
                    <TriggerLinesContainer name="starts" formName={formName} />
                    {/*)}*/}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ float: 'left', marginBottom: '0.5em' }}>
                      <div style={{ float: 'right' }}>
                        &nbsp;
                        <Helper
                          disabled={isBuyDone}
                          text={<Translate id="smartTrade.infoDontDoStart"
                            data={{ side: <Translate id={'smartTrade.' + (isBuy ? 'buy' : 'sell')} /> }}
                          />}
                        />
                      </div>
                      <Field
                        name="dontDoStart"
                        id="dontDoStart"
                        component={SmallCheckBox}
                        type="checkbox"
                        disabled={isBuyDone}
                      />
                      <LabelCheckbox disabled={isBuyDone} htmlFor="dontDoStart">
                        {isBuy ? (
                          <Translate id="smartTrade.dontDoBuy" />
                        ) : (
                          <Translate id="smartTrade.dontDoSell" />
                        )}
                      </LabelCheckbox>
                    </div>
                  </GridItem>
                </>
              )}
              <GridItem xs={12} sm={12} md={!isTemplate ? 6 : 12}>
                <div style={{ float: 'right' }}>
                  <Translate id="orders.capital" />: {capital}$
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={!isTemplate ? 6 : 12}
                md={!isTemplate ? 6 : 12}
              >
                <Percentage
                  formName={formName}
                  name="pourcentage"
                  step={0.01}
                  label={<Translate id="smartTrade.partCapital" />}
                  disabled={isBuyDone}
                  onChange={(event, newValue) => {
                    const elemId = event.target.name;
                    this.changeValue(elemId, newValue);
                  }}
                />
              </GridItem>
              {!isTemplate && (
                <GridItem xs={12} sm={6} md={6}>
                  <Price
                    name="partUse"
                    label={<Translate id="smartTrade.usedCapital" />}
                    endAdornment="$"
                    required={false}
                    max={capital}
                    disabled={isBuyDone}
                    onChange={(event, newValue) => {
                      const elemId = event.target.name;
                      this.changeValue(elemId, newValue);
                    }}
                  />
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={6} md={6}>
                  <Price
                    name="firstQty"
                    label={
                      !dontDoStart ? (
                        isBuy ? (
                          <Translate id="smartTrade.iBuy" />
                        ) : (
                          <Translate id="smartTrade.iSell" />
                        )
                      ) : isBuy ? (
                        <Translate id="smartTrade.iAlreadyBuy" />
                      ) : (
                        <Translate id="smartTrade.iAlreadySell" />
                      )
                    }
                    endAdornment={primStr}
                    disabled={isBuyDone}
                    step={lotStep}
                    required={false}
                    onChange={(event, newValue) => {
                      const elemId = event.target.name;
                      this.changeValue(elemId, newValue);
                    }}
                  />
                </GridItem>
              )}
              {!isTemplate && (
                <GridItem xs={12} sm={6} md={6}>
                  <Price
                    name="secQty"
                    label={
                      isBuy ? (
                        <Translate id="smartTrade.with" />
                      ) : (
                        <Translate id="smartTrade.for" />
                      )
                    }
                    endAdornment={secStr}
                    max={qtySec}
                    step={stepPrice}
                    disabled={isBuyDone}
                    required={false}
                    onChange={(event, newValue) => {
                      const elemId = event.target.name;
                      this.changeValue(elemId, newValue);
                    }}
                  />
                </GridItem>
              )}
              {!isPaperTrading && (
                <GridItem xs={12} sm={12} md={12}>
                  <Grid container style={{ textAlign: 'left' }}>
                    {primStr && (
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel
                          style={{ fontSize: '0.75rem', marginBottom: '5px' }}
                          component="span"
                        >
                          {!isBitmex ? primStr : 'XBT'}{' '}
                          <Translate id="smartTrade.available" />:
                        </InputLabel>
                        {!isBuyDone &&
                          ((!isBuy && !dontDoStart) ||
                            (isBuy && dontDoStart)) &&
                          !isBitmex && (
                            <MyTooltip text={tooltipPrimQty}>
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  const newValue = parseToFloat(
                                    qtyPrim,
                                    lotStep,
                                    true,
                                  );
                                  change(formName, 'firstQty', newValue);
                                  this.changeValue('firstQty', newValue);
                                }}
                              >
                                {parseToFloat(qtyPrim, lotStep, true)}
                              </span>
                            </MyTooltip>
                          )}
                        {(isBuyDone ||
                          (isBuy && !dontDoStart) ||
                          (!isBuy && dontDoStart)) &&
                          !isBitmex && (
                            <MyTooltip text={tooltipPrimQty}>
                              <span>
                                {parseToFloat(qtyPrim, lotStep, true)}
                              </span>
                            </MyTooltip>
                          )}
                        {isBitmex && <span>{qtyPrim}</span>}
                      </Grid>
                    )}
                    {!primStr && <Grid item xs={12} sm={12} md={6} />}
                    {secStr && !isBitmex && (
                      <GridItem xs={12} sm={12} md={6}>
                        <InputLabel
                          style={{ fontSize: '0.75rem', marginBottom: '5px' }}
                          component="span"
                        >
                          {secStr} <Translate id="smartTrade.available" />:
                        </InputLabel>
                        {!isBuyDone &&
                          ((isBuy && !dontDoStart) ||
                            (!isBuy && dontDoStart)) && (
                            <MyTooltip text={tooltipSecQty}>
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  const newValue = parseToFloat(
                                    qtySec,
                                    stepPrice,
                                    true,
                                  );
                                  change(formName, 'secQty', newValue);
                                  this.changeValue('secQty', newValue);
                                }}
                              >
                                {parseToFloat(qtySec, stepPrice, true)}
                              </span>
                            </MyTooltip>
                          )}
                        {(isBuyDone ||
                          (!isBuy && !dontDoStart) ||
                          (isBuy && dontDoStart)) && (
                            <MyTooltip text={tooltipSecQty}>
                              <span>{parseToFloat(qtySec, stepPrice, true)}</span>
                            </MyTooltip>
                          )}
                      </GridItem>
                    )}
                    {!secStr && <GridItem xs={12} sm={12} md={6} />}
                    {isBitmex && <GridItem xs={12} sm={12} md={6} />}
                  </Grid>
                </GridItem>
              )}
              {isLeverage && (
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: '30px' }}>
                  <div style={{ float: 'left', margin: '0.5em 0px 24px 0px' }}>
                    <div style={{ float: 'right' }}>
                      &nbsp;
                      <Helper
                        disabled={isBuyDone}
                        text={
                          isBitmex ? (
                            <Translate id="smartTrade.infoLeverageBitmex" />
                          ) : (
                            <Translate id="smartTrade.infoLeverage" />
                          )
                        }
                      />
                    </div>
                    <Field
                      name="leverageCB"
                      id="leverageCB"
                      component={SmallCheckBox}
                      type="checkbox"
                      disabled={isBuyDone}
                      onChange={() => {
                        this.changeValue('leverage', 1);
                        // this.props.change(formName, 'leverage', 1);
                      }}
                    />
                    <LabelCheckbox htmlFor="leverageCB">
                      <Translate id="smartTrade.leverage" />
                    </LabelCheckbox>
                  </div>
                </GridItem>
              )}
              {isLeverage && (
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: '12px' }}>
                  {leverageCB && (
                    <Field
                      className="form-control"
                      name="leverage"
                      min={minLeverage}
                      max={maxLeverage}
                      label={<Translate id="smartTrade.leverage" />}
                      component={ReactTextField}
                      fullWidth={true}
                      type="number"
                      parse={(value) =>
                        parseToFloat(value, isKraken ? 1 : 0.01)
                      }
                      inputProps={{ step: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">X</InputAdornment>
                        ),
                      }}
                      onChange={(event, newValue) => {
                        const elemId = event.target.name;
                        this.changeValue(elemId, newValue);
                      }}
                      disabled={isBuyDone}
                      required
                    />
                  )}
                  {leverage === 0 && isBitmex && <span>Cross</span>}
                </GridItem>
              )}
            </GridContainer>
            {!isEmpty(errorsList) && (
              <>
                {errorsList.map((error, i) => (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    key={i}
                    style={{ color: dangerColor }}
                  >
                    {error}
                  </GridItem>
                ))}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.formName);
  return {
    currentPrice: currentPriceSelector(state, props),
    capital: capitalSelector(state, props),
    pair: formSelector(state, 'pair'),
    qtyPrim: qtyPrimSelector(state, props),
    qtySec: qtySecSelector(state, props),
    tokenPrim: tokenPrimSelector(state, props),
    tokenSec: tokenSecSelector(state, props),
    isBuy: formSelector(state, 'isBuy'),
    TVType: formSelector(state, 'TVType'),
    leverageCB: formSelector(state, 'leverageCB'),
    leverageBuy: leverageBuySelector(state, props),
    leverageSell: leverageSellSelector(state, props),
    isBuyDone: formSelector(state, 'isBuyDone'),
    isUpdate: formSelector(state, 'isUpdate'),
    stepPrice: stepPriceSelector(state, props),
    lotStep: stepQtySelector(state, props),
    exchange: formSelector(state, 'accountId'),
    leverage: formSelector(state, 'leverage'),
    pourcentage: formSelector(state, 'pourcentage'),
    dontDoStart: formSelector(state, 'dontDoStart'),
    isPaperTrading: formSelector(state, 'isPaperTrading'),
    isTemplate: formSelector(state, 'isTemplate'),
    serverUrl: formSelector(state, 'serverUrl'),
    templateGuid: formSelector(state, 'templateGuid'),
    syncErrors: getFormSyncErrors(props.formName)(state),
    starts: formSelector(state, 'starts'),
    startAveragePrice: formSelector(state, 'startAveragePrice'),
    secPrice: secPriceSelector(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    touch: bindActionCreators(touch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartContainer);
