import { isBinanceFuture, isEmpty, isNull, isTriggerLineEmpty, parseToFloat } from '../Common';
import { between, floatSafeRemainder, greaterThan } from '../../views/SmartTrade/validate';
import { isRequired } from '../form';

const getTranslateLineIndicator = ({translate, name, count}) => {
  return translate('error.lineIndicator', {
    info: translate(`smartTrade.${name}`),
    line: count + 1,
  });
}
const validateTriggers = (values, props, typeName) => {
  const isStart = typeName === 'starts';
  const isTarget = typeName === 'targets';
  const isStop = typeName === 'stops';
  const { translate, currentPrice, stepPrice, pairInfo } = props;
  const { isBuy, isBuyDone, startAveragePrice, isUpdate, dontDoStart, accountId, isTemplate } = values;
  const triggerLock = values[`${typeName}Lock`] ?? false;
  const triggers = values[typeName];
  if (triggerLock === true || isEmpty(triggers)) return null;

  const isBinanceFutures = accountId && accountId.exchange ? isBinanceFuture(accountId.exchange) : false;

  // isUpdate && isBuyDone pour que les tps n'aient pas d'erreur si le start limit est plus bas que current price
  const shouldUseCurrentPrice = isBuyDone || (isUpdate && isBuyDone) || dontDoStart;
  const limit = shouldUseCurrentPrice ? currentPrice : startAveragePrice;
  let side = isBuy;
  if (isStop) side = !side;
  let greaterBetween = side;
  if (isStart) greaterBetween = false;


  const errors = [];
  const triggersErrors = [];
  let totalPercent = 0;
  let count = -1;
  for (const trigger of triggers) {
    if (isTriggerLineEmpty(trigger)) return;
    const { pourcentage, price, profit, trailing, trailingCB, optionsCB, triggerPrice, triggerCB } = trigger;
    const nbrPercent = parseFloat(pourcentage);
    if (!isNaN(nbrPercent)) totalPercent += nbrPercent;
    if (trigger.isDone) return;
    count++;

    //Start ?
    if ((isEmpty(pourcentage) && !isEmpty(price)) || greaterThan(nbrPercent, 0.01))
      triggersErrors.push(
        getTranslateLineIndicator({translate, name: 'part', count}) +
        translate('error.greater', { val: 0.01 }).toLowerCase()
      );

    //from start
    // if (isBinanceMargin) return;

    if (isEmpty(price)) {
      if (!isTemplate)
        triggersErrors.push(
          getTranslateLineIndicator({translate, name: 'price', count}) +
          translate('error.empty').toLowerCase()
        );
    } else {
      if (floatSafeRemainder(price, stepPrice) > 0)
        triggersErrors.push(
          getTranslateLineIndicator({ translate, name: 'price', count }) +
          translate('error.stepPrice', { val: stepPrice }).toLowerCase()
        );

      let min = side ? limit : 0;
      let max = side ? '' : limit;

      if (isStart && pairInfo?.LimitsPriceMin && pairInfo?.LimitsPriceMax) {
        min = parseToFloat(pairInfo?.LimitsPriceMin ?? 0, stepPrice);
        max = parseToFloat(pairInfo?.LimitsPriceMax ?? '', stepPrice);
      }

      if (!(isStart && (dontDoStart)))
        if (between(price, min, max)) {
          triggersErrors.push(
            getTranslateLineIndicator({ translate, name: 'price', count }) +
            (greaterBetween
              ? translate('error.greater', { val: min }).toLowerCase()
              : translate('error.between', { min, max }).toLowerCase())
          );
        }
    }

    if (!isStart) {
      if (isNull(profit))
        triggersErrors.push(
          getTranslateLineIndicator({translate, name: 'profit', count}) +
          translate('error.empty').toLowerCase()
        );
      else {
        if (profit && !isUpdate) {
          let min = 0.01;
          if (isTarget && greaterThan(profit, min))
            triggersErrors.push(
              getTranslateLineIndicator({ translate, name: 'profit', count }) +
              translate('error.greater', { val: min }).toLowerCase()
            );
          min = -100;
          const max = 0;
          if (isStop && !dontDoStart && between(profit, min, max))
            triggersErrors.push(
              getTranslateLineIndicator({ translate, name: 'profit', count }) +
              translate('error.between', { min, max }).toLowerCase()
            );
        }
      }
    }


    if (optionsCB && trailingCB) { //&& !dontDoStart
      let min = 0.01;
      let max = isBinanceFutures ? 5 : 20;
      if (isStop) {
        min = isBinanceFutures ? -5 : -20;
        max = -0.01;
      }
      if (between(trailing, min, max))
        triggersErrors.push(
          getTranslateLineIndicator({translate, name: 'trailing', count}) +
          translate('error.between', { min, max }).toLowerCase()
        );

      if (isRequired(trailing))
        triggersErrors.push(
          getTranslateLineIndicator({translate, name: 'trailing', count}) +
          translate('central.required').toLowerCase()
        );
      // if (isRequired(trailing))
      //   errors[`${typeName}[${count}].trailing`] = translate('central.required');
    }

    if (optionsCB && triggerCB && !dontDoStart) {
      if (isRequired(triggerPrice))
        triggersErrors.push(
          getTranslateLineIndicator({translate, name: 'triggerPoint', count}) +
          translate('central.required').toLowerCase()
        );
      // let min = side ? limit : 0;
      // let max = side ? '' : limit;
      // if (between(triggerPoint, min, max))
      //   triggersErrors.push(
      //     getTranslateLineIndicator({translate, name: 'triggerPoint', count}) +
      //     (!greaterBetween
      //       ? translate('error.greater', { val: min }).toLowerCase()
      //       : translate('error.between', { min, max }).toLowerCase())
      //   );
    }

  }
  if (totalPercent > 100) triggersErrors.push(translate('error.sumAlert'));
  if (isStart && (isEmpty(triggers) || totalPercent === 0))
    if (!(isStart && isUpdate && isTemplate))
      triggersErrors.push(translate('error.greater', { val: 0 }).toLowerCase());

  if (triggersErrors.length > 0) errors[typeName] = triggersErrors.join('\n');
  // console.log(errors);
  return errors;
};
export default validateTriggers;
